<!--用户中心 -->
<template>
    <div class="view-user-center">
        <div class="user-center-content">
            <div class="top-info">
                <div class="user-avatar">
                    <img :src="data.avatar"/>
                </div>
                <div class="user-name">
                    <span>{{ data.userName }}</span>
                </div>
            </div>
            <div class="my-works">
                <div class="my-works-title box-title">我的作品</div>
                <div class="works-item" v-if="myWorksList.length">
                    <div class="item-image-box" @click="toDetail()">
                        <!--                        <img id="my-works-image" class="item-image" :src="myWorksList[0].CoverURL || require('../static/image/userCenter/default_works.png')"/>-->
                        <img :src="myWorksImage" class="item-image" @error="setDefaultImage"/>
                        <div class="icon-play-box">
                            <img class="icon-play" src="../static/image/icon/icon_spbf.png"/>
                        </div>
                        <div class="item-time">
                            <span>{{ myWorksList[0].Duration }}</span>
                        </div>
                    </div>
                    <div class="item-info-box">
                        <div class="item-title">{{ myWorksList[0].WorkTitle }}</div>
                        <div class="item-info-other">
                            <div class="item-author">
                                <span>{{myWorksList[0].AuditStatusEnum}}</span>
                                <a v-if="myWorksList[0].AuditStatus != '3'" href="javascript:" @click="toEdit" class="to-edit-work">编辑作品</a>
                            </div>
                            <div class="item-agree">
                                <img src="../static/image/icon/icon_70_ydz.png"/>
                                <span>{{myWorksList[0].VoteNum}}</span>
                            </div>
<!--                            <div class="item-edit" @click="toEdit">-->
<!--                                <img src="../static/image/icon/icon_70_fx.png"/>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-agree-history">
                <div class="my-agree-title box-title">我的点赞</div>
                <div class="agree-list">
                    <div class="agree-item" v-for="item in agreeList" :key="item.id" @click="toDetail(item.Id)">
                        <div class="item-image">
                            <img :src="item.ImgUrl"/>
                        </div>
                        <div class="item-title">{{ item.WorkTitle }}</div>
                        <div class="item-info">
                            <div class="item-user">{{item.UserName}}</div>
                            <div class="item-agree-num">{{item.VoteNum}} 赞</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Back></Back>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {Toast} from 'vant'
    import core from '../core/index'
    import {myWorksList, myVoteLogList, userInfo} from '../server'
    import getStrParams from "../utils/getStrParams";
    import Back from "../components/Back";
    import initWXAPI from "../utils/initWXAPI";
    import WXShare from "../utils/WXShare";

    Vue.use(Toast)
    export default {
        name: "UserCenter",
        components: {Back},
        data() {
            return {
                type: '1',
                sessionId: '',
                data: {
                    userName: '',
                    avatar: '',
                    title: '',
                },
                myWorksList: [],
                agreeList: [],
                myWorksImage: ''
            }
        },
        methods: {
            toDetail(id) {
                const myWork = this.myWorksList && this.myWorksList[0]
                if (id) {
                    this.$router.push({path: 'detail/' + id})
                } else if (myWork && myWork.VideoId && myWork.PlayURL) {
                    this.$router.push({path: 'detail/' + myWork.GiId})
                } else {
                    Toast('视频转码中，请稍后刷新重试')
                }
            },
            async getUserInfo() {
                const data = await userInfo({Session: this.sessionId})
                const _data = data.Data;
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                this.data.userName = _data.NickName;
                this.data.avatar = _data.Face || require('../static/image/userCenter/default_avatar.png');
            },
            async getMyWorksList() {
                const data = await myWorksList({SessionId: this.sessionId});
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                this.myWorksList = data.Data.list
                this.myWorksImage = data.Data.list.length && data.Data.list[0].CoverURL
            },
            async getMyAgreeList() {
                const data = await myVoteLogList({SessionId: this.sessionId})
                if (data.ErrorMsg) {
                    Toast(data.ErrorMsg)
                    return;
                }
                this.agreeList = data.Data.list
            },
            setDefaultImage() {
                this.myWorksImage = require('../static/image/userCenter/default_works.png')
            },
            initPageShare() {
                initWXAPI.init(this.$store.state.url)
                const options = {
                    title: '红色故事演讲大赛',
                    desc: '2022上海市民文化节',
                    imgUrl: 'https://redsw1.02lu.com/share_logo.png',
                    link: `https://redsw1.02lu.com/`
                }
                WXShare.initWXShare(options)
                WXShare.initWXTimelineShare(options)
            },
            toEdit() {
                this.$router.push({path: 'uploadWorks', query: {hasWorks: '1'}})
            }
        },
        created() {
            this.sessionId = core.cookie.getStorage('sessionId')
            this.getUserInfo()
            this.getMyWorksList()
            this.getMyAgreeList()
            this.initPageShare()
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/common";

    .view-user-center {
        background: url("../static/image/userCenter/bg_user.png") #f3f3f3 no-repeat top left;
        background-size: contain;
        padding-top: toRem(20);
        min-height: 100vh;

        .user-center-content {
            .top-info {
                display: flex;
                padding-left: toRem(40);

                .user-avatar {
                    width: toRem(120);
                    height: toRem(120);
                    border-radius: 50%;
                    overflow: hidden;
                    border: toRem(4) solid #f2f2f2;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .user-name {
                    color: #fff;
                    padding: toRem(40) 0 0 toRem(30);
                    font-size: toRem(34);
                }
            }

            .my-works {
                margin-top: toRem(120);
                padding: toRem(20);
                .to-edit-work{
                    color: #6caff5;
                    margin-left: toRem(40);
                    font-size: toRem(20);
                    vertical-align: middle;
                }
            }

            .my-agree-history {
                padding: toRem(20);

                .agree-list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .agree-item {
                        /*flex: 1;*/
                        width: 40%;
                        padding: toRem(12);
                        background: #fff;
                        border-radius: toRem(12);
                        overflow: hidden;
                        box-shadow: toRem(0) toRem(0) toRem(12) #ccc;
                        margin: toRem(12);

                        .item-image {
                            height: toRem(130);
                            border-top-left-radius: toRem(8);
                            border-top-right-radius: toRem(8);
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .item-title {
                            padding: toRem(20) 0 toRem(10);
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: toRem(30);
                            color: #666;
                        }

                        .item-info {
                            display: flex;
                            padding-bottom: toRem(15);

                            .item-user {
                                flex: 1;
                                color: #aaa;
                                padding-top: toRem(6);
                            }

                            .item-agree-num {
                                width: 40%;
                                background: #eb1e27;
                                text-align: center;
                                color: #fff;
                                border-radius: toRem(40);
                                padding: toRem(6) toRem(10);
                                font-size: toRem(24);
                            }
                        }
                    }
                }
            }

            .box-title {
                background: url("../static/image/icon/icon_jux.png") no-repeat top left;
                background-size: contain;
                font-size: toRem(30);
                margin: toRem(20) 0;
                padding: 0 toRem(30);
            }
        }
    }
</style>